import Sku_Api from 'app/api/Sku_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import SkuForm from 'app/components/Forms/Sku';
import SkuIntegrationForm from 'app/components/Forms/Sku/Integration';
import SkuInventoryForm from 'app/components/Forms/Sku/Inventory';
import SkuLogisticsForm from 'app/components/Forms/Sku/Logistics';
import SkuContext from 'app/contexts/SkuContext';
import { IDataRelation } from 'app/types/data/IData';
import { IDataSku, SkuRelations } from 'app/types/data/IDataSku';
import DisplayAttributes from 'app/views/curated/attribute/components/DisplayAttributes';
import DisplayAttributesToSelect from 'app/views/curated/attribute/components/DisplayAttributesToSelect';
import DisplayOrientation from 'app/views/curated/orientation/components/DisplayOrientation';
import DisplayUnit from 'app/views/curated/unit/components/DisplayUnit';
import React from 'react';
import { useLocation } from 'react-router-dom';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import useAuth from 'app/hooks/useAuth';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import Funcionalidade_Api from 'app/api/Funcionalidade_Api';
declare interface ISkuFormContainer {
  data?: IDataSku;
  relation?: IDataRelation<SkuRelations>;
  onClickAttach?: () => void;
  onSubmit: (item: any) => void;
  cancel: () => void;
  reloadMediaRelation?: boolean;
  editDisabled?: boolean;
  setSelected: (data) => void;
  uuid?: string;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const SkuFormContainer = (props: ISkuFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [data, setData] = React.useState<IDataSku | undefined>(props.data);
  const [activeTab, setActiveTab] = React.useState<string>('0')
  const [reloadMediaRelation, setReloadMediaRelation] = React.useState<boolean>(false)

  function getMenuSequency(sequency?: any, menus?: any[]) {
    return menus?.find(x => x.sequency === sequency)
  }
  async function getItemsMenu() {
    try {
      let tabMenu = [
        {
          title: 'Gestão Estoque',
          component: (
            <SkuInventoryForm data={props.data} onSubmit={props.onSubmit} relation={props.relation} />
          ),
        },
        {
          title: 'Gestão Logística',
          component: (
            <SkuLogisticsForm data={props.data} onSubmit={props.onSubmit} relation={props.relation} />
          ),
        },
        {
          title: 'Gestão Integração',
          component: (
            <SkuIntegrationForm
              data={props.data}
              onSubmit={props.onSubmit}
              relation={props.relation}

            />
          ),
        },
        {
          tag: 'atributo',
          title: 'Atributos',
          component: (
            <DisplayAttributesToSelect
              displayName="Atributos"
              relation={{ id: props.data!.id as number, relation: 'Sku' }}
              local="sku"

            />
          ),
        },
        {
          title: 'Atributos (OLD)',
          component: (
            <DisplayAttributes
              relation={{ id: props.data!.id as number, relation: 'Sku' }}
              view="grid"
              local="sku"
            />
          ),
        },
        {
          tag: 'unidade',
          title: 'Unidades',
          component: <DisplayUnit relation={{ id: props.data!.id as number, relation: 'Sku' }} />,
        },
        {
          tag: 'orientacao',
          title: 'Orientações',
          component: (
            <DisplayOrientation relation={{ id: props.data!.id as number, relation: 'Sku' }} />
          ),
        },
        {
          title: 'Mídia',
          component: (
            <GridAttachments
              hasCardView
              relationData={data}
              apiModel={Sku_Api}
              onClickAttach={props.onClickAttach}
              reloadMediaRelation={reloadMediaRelation}

            />
          ),
        },
      ]

      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)

      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }

      let res;
      if (props.relation) {
        const funcFilhos = await Funcionalidade_Api.listPai(funcionalidade.funcionalidade.id);
        if (funcFilhos.find(f => f.nome === 'SKUS')) {

          const filho = funcFilhos.find(f => f.nome === 'SKUS')!.corelacao_id

          if (!!filho) {
            const apx = await BpmOrder_Api.getBpmItemByFuncionalidadeFilho(filho, menu.id);

            // res = await BpmOrder_Api.getBpmItem(apx.menu_item_id ?? 0);
            res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("SKUS", menu.id)
          } else {
            res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
          }

        } else {
          res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("SKUS", menu.id);

        }
      } else {
        res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
      }

      if (res && res.length > 0) {
        let nItems = res

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];
          (x.campos ?? []).map(y => {
            const teste = x.bpmCampos?.find(f => f.funcionalidade_campo_id === y.id)
            y.hide = teste?.hidden;
            y.required = teste?.required;
          })
        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

        tabMenu = [
          {
            title: 'Gestão Estoque',
            component: (
              <SkuInventoryForm campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'gestão estoque')?.campos} data={props.data} onSubmit={(item) => {
                if (props.onSubmit)
                  props.onSubmit(item)
                setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'gestão estoque')?.sequency)

              }} relation={props.relation}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'gestão estoque')?.sequency}

              />
            ),
          },
          {
            title: 'Gestão Logística',
            component: (
              <SkuLogisticsForm campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'gestão logística')?.campos} data={props.data} onSubmit={(item) => {
                if (props.onSubmit)
                  props.onSubmit(item)
                setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'gestão logística')?.sequency)

              }} relation={props.relation}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'gestão logística')?.sequency}
              />
            ),
          },
          {
            title: 'Gestão Integração',
            component: (
              <SkuIntegrationForm
                data={props.data}
                onSubmit={(item) => {
                  props.onSubmit(item)
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'gestão integração')?.sequency)

                }}
                relation={props.relation}
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'gestão integração')?.campos}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'gestão integração')?.sequency}
              />
            ),
          },
          {
            tag: 'atributo',
            title: 'Atributos',
            component: (
              <DisplayAttributesToSelect
                displayName="Atributos"
                relation={{ id: props.data!.id as number, relation: 'Sku' }}
                local="sku"
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos')?.campos}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos')?.sequency].nome)
                }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos')?.sequency}
              />
            ),
          },
          {
            title: 'Atributos (OLD)',
            component: (
              <DisplayAttributes
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos (old)')?.campos}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos (old)')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos (old)')?.sequency].nome)
                }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atributos (old)')?.sequency}
                relation={{ id: props.data!.id as number, relation: 'Sku' }}
                view="grid"
                local="sku"
              />
            ),
          },
          {
            tag: 'unidade',
            title: 'Unidades',
            component: <DisplayUnit campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'unidades')?.campos}
              sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'Unidades')?.sequency}
              onSubmit={() => {
                setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'unidades')?.sequency)
                localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'unidades')?.sequency]?.nome)

              }}
              relation={{ id: props.data!.id as number, relation: 'Sku' }} />,
          },
          {
            tag: 'orientacao',
            title: 'Orientações',
            component: (
              <DisplayOrientation onSubmit={() => {
                setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'orientações')?.sequency)
                localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'orientações')?.sequency].nome)
              }} campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'orientações')?.campos} sequency={getMenuSequency(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'orientações')?.sequency, nItems)} relation={{ id: props.data!.id as number, relation: 'Sku' }} />
            ),
          },
          {
            title: 'Mídia',
            component: (
              <GridAttachments
                hasCardView
                relationData={data}
                apiModel={Sku_Api}
                onClickAttach={props.onClickAttach}
                reloadMediaRelation={reloadMediaRelation}
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'mídia')?.campos}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'mídia')?.sequency}
                onSubmit={() => setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'mídia')?.sequency)}

              />
            ),
          },
        ]

        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => m.title.toLowerCase() === x.nome.split(' - ')[0].toLowerCase())
            if (menuItem) {
              const menuItem = tabMenu.find(m => m.title.toLowerCase() === x.nome.split(' - ')[0].toLowerCase())
              if (menuItem) {
                const nomeTab = x.nome.split(' - ')
                nTabs.push({
                  ...menuItem, ...x,
                  title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title
                })
              }
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      } else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);
  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);
  React.useEffect(() => {
    setReloadMediaRelation(!!props.reloadMediaRelation)
    getItemsMenu()
  }, [props.reloadMediaRelation]);
  return (
    <SkuContext.Provider value={data}>
      <FormContainer
        activeTab={activeTab}
        uuid={props?.uuid}
        mainForm={
          <SkuForm
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            editDisabled={props.editDisabled}
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            sequency={props.sequency}
            campos={props.campos}
            typeForm={props.typeForm}
          />
        }
        tabs={
          data && tabsMenu
        }
      />
    </SkuContext.Provider>
  );
};

export default SkuFormContainer;
