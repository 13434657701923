import Workflow_Api from 'app/api/Workflow_Api';
import FormContainer from 'app/components/Containers/FormContainer';
import GridAttachments from 'app/components/Displays/Attachments/Grid';
import WorkflowForm from 'app/components/Forms/Workflow';
import WorkflowContext from 'app/contexts/WorkflowContext';
import { IDataRelation } from 'app/types/data/IData';
import { IDataWorkflow, WorkflowRelations } from 'app/types/data/IDataWorkflow';
import DisplayActivity from 'app/views/partners/activity/components/DisplayActivity';
import ActivityKanban from 'app/views/partners/activity/components/Kanban';
import DisplayWorkflowPhases from 'app/views/partners/workflow_phases/components/DisplayWorkflowPhases';
import React from 'react';
import GanttWorkflow from '../../../activity/components/GanttWorkflow';
import { useLocation } from 'react-router-dom';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import useAuth from 'app/hooks/useAuth';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import Funcionalidade_Api from 'app/api/Funcionalidade_Api';
declare interface IWorkflowFormContainer {
  data?: IDataWorkflow;
  relation?: IDataRelation<WorkflowRelations>;
  onClickAttach?: () => void;
  onSubmit: (item?: IDataWorkflow) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid: string;
  editDisabled?: boolean;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const WorkflowFormContainer = (props: IWorkflowFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [data, setData] = React.useState<IDataWorkflow | undefined>(props.data);
  const [activeTab, setActiveTab] = React.useState<string>('0')

  async function getItemsMenu() {
    try {
      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)
      let tabMenu = [
        {
          title: 'Etapas',
          component: (
            <DisplayWorkflowPhases
              relation={{ id: props.data!.id as number, relation: 'Workflow' }}
            />
          ),
        },
        {
          title: 'Atividades',
          component: (
            <DisplayActivity
              displayName="Atividades"
              relation={{ id: props.data!.id as number, relation: 'Workflow' }}
              view="grid"
            />
          ),
        },
        {
          title: 'Árvore de Atividades',
          component: (
            <DisplayActivity
              displayName="Árvore de Atividades"
              relation={{ id: props.data!.id as number, relation: 'Workflow' }}
              view="tree"
            />
          ),
        },
        {
          title: 'Quadro',
          component: (
            <ActivityKanban relation={{ id: props.data!.id as number, relation: 'Workflow' }} />
          ),
        },
        {
          title: 'Cronograma',
          component: (
            <GanttWorkflow relation={{ id: props.data!.id as number, relation: 'Workflow' }} />
          ),
        },
        /* {
      title: 'Orientações',
      component: (
        <DisplayOrientation
          relation={{ id: props.data!.id as number, relation: 'Workflow' }}
        />
      ),
    }, */
        {
          title: 'Anexos',
          component: (
            <GridAttachments
              relationData={data}
              apiModel={Workflow_Api}
              onClickAttach={props.onClickAttach}
            />
          ),
        },
      ]
      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }
      let res;
      if (props.relation) {
        const funcFilhos = await Funcionalidade_Api.listPai(funcionalidade.funcionalidade.id);
        if (funcFilhos.find(f => f.nome === 'WORKFLOW')) {

          const filho = funcFilhos.find(f => f.nome === 'WORKFLOW')!.corelacao_id

          if (!!filho) {
            const apx = await BpmOrder_Api.getBpmItemByFuncionalidadeFilho(filho, menu.id);

            // res = await BpmOrder_Api.getBpmItem(apx.menu_item_id ?? 0);
            res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("WORKFLOW", menu.id)
          } else {
            res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
          }

        } else {
          res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("WORKFLOW", menu.id);

        }
      } else {
        res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
      } if (res && res.length > 0) {
        let nItems = res

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];
          (x.campos ?? []).map(y => {
            const teste = x.bpmCampos?.find(f => f.funcionalidade_campo_id === y.id)
            y.hide = teste?.hidden;
            y.required = teste?.required;
          })
        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

        tabMenu = [
          {
            title: 'Etapas',
            component: (
              <DisplayWorkflowPhases
                relation={{ id: props.data!.id as number, relation: 'Workflow' }}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'etapas')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'etapas')?.sequency].nome)
                }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'etapas')?.sequency}
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'etapas')?.campos}
              />
            ),
          },
          {
            title: 'Atividades',
            component: (
              <DisplayActivity
                displayName="Atividades"
                relation={{ id: props.data!.id as number, relation: 'Workflow' }}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atividades')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atividades')?.sequency]?.nome)
                }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atividades')?.sequency}
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atividades')?.campos}
                view="grid"
              />
            ),
          },
          {
            title: 'Árvore de Atividades',
            component: (
              <DisplayActivity
                displayName="Árvore de Atividades"
                relation={{ id: props.data!.id as number, relation: 'Workflow' }}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'árvore de Atividades')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'árvore de Atividades')?.sequency]?.nome)
                }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'árvore de Atividades')?.sequency}
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'árvore de atividades')?.campos}
                view="tree"
              />
            ),
          },
          {
            title: 'Quadro',
            component: (
              <ActivityKanban campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'quadro')?.campos} relation={{ id: props.data!.id as number, relation: 'Workflow' }} />
            ),
          },
          {
            title: 'Cronograma',
            component: (
              <GanttWorkflow campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'cronograma')?.campos} relation={{ id: props.data!.id as number, relation: 'Workflow' }} />
            ),
          },
          /* {
        title: 'Orientações',
        component: (
          <DisplayOrientation
            relation={{ id: props.data!.id as number, relation: 'Workflow' }}
          />
        ),
      }, */
          {
            title: 'Anexos',
            component: (
              <GridAttachments
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'anexos')?.campos}
                onSubmit={() => setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'anexos')?.sequency)}
                relationData={data}
                apiModel={Workflow_Api}
                onClickAttach={props.onClickAttach}
              />
            ),
          },
        ]

        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => m.title.toLowerCase() === x.nome.split(' - ')[0].toLowerCase())
            if (menuItem) {
              const menuItem = tabMenu.find(m => m.title.toLowerCase() === x.nome.split(' - ')[0].toLowerCase())
              if (menuItem) {
                const nomeTab = x.nome.split(' - ')
                nTabs.push({
                  ...menuItem, ...x,
                  title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title
                })
              }
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      } else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);

  return (
    <WorkflowContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        activeTab={activeTab}
        uuid={props?.uuid}
        mainForm={
          <WorkflowForm
            setSelected={(data) => props.setSelected(data)}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            data={data}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            editDisabled={props.editDisabled}
            campos={props.campos}
            sequency={props.sequency}
            typeForm={props.typeForm}
          />
        }
        tabs={
          !data?.id
            ? undefined
            : tabsMenu
        }
      />
    </WorkflowContext.Provider>
  );
};

export default WorkflowFormContainer;
