import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, InputAdornment, styled, Typography } from '@mui/material';
import Sku_Api from 'app/api/Sku_Api';
import AlertMessage from 'app/components/Alert';
import NumberInput from 'app/components/Inputs/NumberInput/NumberInput';
import Toast from 'app/components/Toast';
import useTrailHistory from 'app/hooks/useTrailHistory';
import { IDataSku, IDataSkuErrors } from 'app/types/data/IDataSku';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import { setDataValue } from 'app/utils/utils';
import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { initialValues } from '..';
import { SkuSchema } from '../../@Schemas/SkuSchema';
import { IFormSku } from '../../@Types/IFormSku';
import useAuth from 'app/hooks/useAuth';

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const UPDATE_MSG = getMessage('Sku', 'update');
const CREATE_MSG = getMessage('Sku', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const SkuInventoryForm = (props: IFormSku) => {
  const { getConfigCampo } = useAuth();

  const [data, setData] = React.useState<IDataSku>(props.data || initialValues);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);

  useTrailHistory({
    selected: data,
    selectedName: data?.name,
    displayName: 'Gestão Estoque',
    toggleView: () => { },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
  } = useForm<IDataSku>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(SkuSchema),
  });

  React.useEffect(() => {
    setDataValue(props.data, setValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.data]);

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function onSubmit() {
    debugger
    console.log("Form submitted:", data);
    const campos = [
      {
        nome: 'Estoque',
        campo: 'nr_estoque'
      },
      {
        nome: 'Estoque Mínimo',
        campo: 'nr_estoque_minimo'
      },
      {
        nome: 'Largura',
        campo: 'nr_largura'
      },
      {
        nome: 'Altura',
        campo: 'nr_altura'
      },
      {
        nome: 'Profundidade',
        campo: 'nr_profundidade'
      },
      {
        nome: 'Peso',
        campo: 'nr_peso'
      }
    ]
    debugger
    campos.forEach(x => {
      if (!data[x.campo] && getConfigCampo(x.nome, props.campos ?? []).required)
        return setToast({
          open: true,
          message: `Campo ${x.nome} obrigatório`,
          severity: 'error',
        })
    })

    setLoading(true);
    try {
      // var newData: IDataSku;
      if (data.id) {
        await Sku_Api.update(data, props.relation);
      } else {
        await Sku_Api.create(data, props.relation);
      }
      if (props.onSubmit) props.onSubmit();
      // setData(newData);
      // setDataValue(newData, setValue);
      setToast({
        open: true,
        message: data.id ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataSkuErrors | unknown) {
      if (error) {
        const err = error as IDataSkuErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  return (
    <FormBox id="formik-Sku-Inventory" autoComplete="off" >

      {/*Fields*/}
      <Grid container spacing={1} maxWidth={800}>
        <Grid item xs={12}>
          {errors.message?.message && errors.status && (
            <AlertMessage title={errors.status.message} message={errors.message.message} />
          )}
        </Grid>
        {getConfigCampo && !getConfigCampo('Estoque', props.campos ?? []).hidden &&
          <Grid item xs={12} md={6}>
            <Controller
              name="nr_estoque"
              control={control}
              defaultValue={initialValues.nr_estoque}
              render={({ field: { onChange } }) => (
                <NumberInput
                  id="nr_estoque"
                  label="Estoque"
                  name="nr_estoque"
                  type="text"
                  value={data.nr_estoque}
                  autoComplete={'off'}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  size="small"
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.nr_estoque?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, nr_estoque: parseFloat(e.target.value) });
                    onChange(e);
                  }}
                />
              )}
            />
          </Grid>
        }

        {getConfigCampo && !getConfigCampo('Estoque Mínimo', props.campos ?? []).hidden &&
          <Grid item xs={12} md={6}>
            <Controller
              name="nr_estoque_minimo"
              control={control}
              defaultValue={initialValues.nr_estoque_minimo}
              render={({ field: { onChange } }) => (
                <NumberInput
                  id="nr_estoque_minimo"
                  name="nr_estoque_minimo"
                  label="Estoque Mínimo"
                  type="text"
                  value={data.nr_estoque_minimo}
                  autoComplete={'off'}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  size="small"
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.nr_estoque_minimo?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, nr_estoque_minimo: parseFloat(e.target.value) });
                    onChange(e);
                  }}
                />
              )}
            />
          </Grid>
        }

        {getConfigCampo && !getConfigCampo('Largura', props.campos ?? []).hidden &&
          <Grid item xs={12} md={6}>
            <Controller
              name="nr_largura"
              control={control}
              defaultValue={initialValues.nr_largura}
              render={({ field: { onChange } }) => (
                <NumberInput
                  id="nr_largura"
                  name="nr_largura"
                  label="Largura"
                  type="text"
                  value={data.nr_largura}
                  autoComplete={'off'}
                  fullWidth
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  size="small"
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.nr_largura?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, nr_largura: parseFloat(e.target.value) });
                    onChange(e);
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        }

        {getConfigCampo && !getConfigCampo('Altura', props.campos ?? []).hidden &&
          <Grid item xs={12} md={6}>
            <Controller
              name="nr_altura"
              control={control}
              defaultValue={initialValues.nr_altura}
              render={({ field: { onChange } }) => (
                <NumberInput
                  id="nr_altura"
                  label="Altura"
                  type="text"
                  value={data.nr_altura}
                  autoComplete={'off'}
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.nr_altura?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, nr_altura: parseFloat(e.target.value) });
                    onChange(e);
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        }

        {getConfigCampo && !getConfigCampo('Profundidade', props.campos ?? []).hidden &&
          <Grid item xs={12} md={6}>
            <Controller
              name="nr_profundidade"
              control={control}
              defaultValue={initialValues.nr_profundidade}
              render={({ field: { onChange } }) => (
                <NumberInput
                  id="nr_profundidade"
                  label="Profundidade"
                  type="text"
                  value={data.nr_profundidade}
                  autoComplete={'off'}
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.nr_profundidade?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, nr_profundidade: parseFloat(e.target.value) });
                    onChange(e);
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">cm</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        }

        {getConfigCampo && !getConfigCampo('Peso', props.campos ?? []).hidden &&
          <Grid item xs={12} md={6}>
            <Controller
              name="nr_peso"
              control={control}
              defaultValue={initialValues.nr_peso}
              render={({ field: { onChange } }) => (
                <NumberInput
                  id="nr_peso"
                  label="Peso"
                  type="text"
                  value={data.nr_peso}
                  autoComplete={'off'}
                  fullWidth
                  variant="outlined"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  helperText={
                    <Typography color="red" variant="caption" component="span">
                      {errors.nr_peso?.message}
                    </Typography>
                  }
                  onChange={(e) => {
                    setData({ ...data, nr_peso: parseFloat(e.target.value) });
                    onChange(e);
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">g</InputAdornment>,
                  }}
                />
              )}
            />
          </Grid>
        }


      </Grid>

      {/*Buttons*/}
      <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
        <LoadingButton type="submit" color="primary" loading={loading} variant="contained" onClick={onSubmit}>
          {data.id &&
            <>Salvar</>
          }
          {props.sequency && !data.id &&
            <>Salvar e continuar</>
          }
          {!props.sequency && !data.id &&
            <>Salvar</>
          }
        </LoadingButton>
      </Grid>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </FormBox>
  );
};

export default SkuInventoryForm;
