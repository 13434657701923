import { yupResolver } from '@hookform/resolvers/yup';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, FormControl, Grid, InputLabel, MenuItem, Select, styled, TextField, Typography } from '@mui/material';
import GroupUser_Api from 'app/api/GroupUser_Api';
import AlertMessage from 'app/components/Alert';
import TimedTextField from 'app/components/Inputs/TimedTextField/TimedTextField';
import Toast from 'app/components/Toast';
import { useUtilsContext } from 'app/contexts/UtilsContext';
import { IDataGroupUser, IDataGroupUserErrors } from 'app/types/data/IDataGroupUser';
import { ISelectOption } from 'app/types/ISelectOption';
import { IToast } from 'app/types/IToast';
import { getMessage } from 'app/utils/messages';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { GroupUserSchema } from '../@Schemas/GroupUserSchema';
import { IFormGroupUser } from '../@Types/IFormGroupUser';
import Menu_Api from 'app/api/Menu_Api'

const FormBox = styled('form')(({ theme }) => ({
  width: '100%',
}));

const initialValues: IDataGroupUser = {
  id: null,
  name: '',
};

const UPDATE_MSG = getMessage('Grupo de Usuário', 'update');
const CREATE_MSG = getMessage('Grupo de Usuário', 'create');

const initialToast: IToast = {
  open: false,
  message: UPDATE_MSG,
  severity: 'success',
};

const FormGroupUser = (props: IFormGroupUser) => {
  const { resetFormList } = useUtilsContext();
  const [data, setData] = React.useState<IDataGroupUser>(props.data || initialValues);
  const [comboData, setComboData] = React.useState<ISelectOption<IDataGroupUser>[]>([]);
  const [comboOption, setComboOption] = React.useState<ISelectOption<IDataGroupUser> | null>(null);
  const [comboLoading, setComboLoading] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);
  const [toast, setToast] = React.useState<IToast>(initialToast);
  const [search, setSearch] = React.useState<string>('');
  const [optionsMenu, setOptionsMenu] = React.useState<any[]>([]);

  React.useEffect(() => {
    setData((props.data as IDataGroupUser) || initialValues);
    reset(props.data, { keepDefaultValues: true });
    clearErrors();
  }, [resetFormList?.userGroup]);

  React.useEffect(() => {
    if (props.relation) load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.relation, search]);

  React.useEffect(() => {
    getComboMenu()
  }, []);

  const {
    handleSubmit,
    control,
    formState: { errors },
    setError,
    setValue,
    clearErrors,
    reset,
  } = useForm<IDataGroupUser>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    criteriaMode: 'all',
    shouldFocusError: true,
    resolver: yupResolver(GroupUserSchema),
  });

  async function load() {
    setComboLoading(true);
    try {
      const items = await GroupUser_Api.list({ q: search });

      const combo: ISelectOption<IDataGroupUser>[] = items.data.map((item) => ({
        value: `${item.id}`,
        label: item.name,
        data: item,
      }));

      setComboData(combo);
    } catch (error: IDataGroupUserErrors | unknown) {
      if (error) {
        const err = error as IDataGroupUserErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setComboLoading(false);
    }
  }

  async function onSubmit(data: IDataGroupUser) {
    setLoading(true);
    try {
      var newData: IDataGroupUser;

      if (data.id) {
        newData = await GroupUser_Api.update(data, props.relation);
      } else {
        newData = await GroupUser_Api.create(data, props.relation);
        if (props.onSubmit) props.onSubmit();
      }
      setData(newData);
      setValue('id', newData.id);
      setToast({
        open: true,
        message: props.type === 'update' ? UPDATE_MSG : CREATE_MSG,
        severity: initialToast.severity,
      });
    } catch (error: IDataGroupUserErrors | unknown) {
      if (error) {
        const err = error as IDataGroupUserErrors;
        err.hookForm?.forEach(({ name, type, message }) => setError(name, { type, message }));
      }
    } finally {
      setLoading(false);
    }
  }

  function handleCloseToast() {
    setToast({ ...initialToast, open: false });
  }

  async function getComboMenu() {
    const res = await Menu_Api.listCombo()
    const itemsOpt = res.map(x => {
      return {
        ...x,
        label: x.nome,
        value: x.id
      }
    })
    setOptionsMenu(itemsOpt)
  }
  return (
    <FormBox id="formik-form-group-user" autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
      {/*Fields*/}
      <Grid container spacing={1} maxWidth={800}>
        <Grid item xs={12}>
          {errors.message?.message && errors.status && (
            <AlertMessage title={errors.status.message} message={errors.message.message} />
          )}
        </Grid>
        {props.relation && (
          <>
            <Grid item xs={12}>
              <Autocomplete
                noOptionsText="Nenhuma Grupo de Usuário"
                options={comboData}
                defaultValue={comboOption}
                value={comboOption}
                onClose={() => setSearch('')}
                loading={comboLoading}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                onChange={(event, option) => {
                  if (option && option.data) {
                    setData(option.data);
                    setComboOption(option);
                    setValue('id', option.data.id);
                    setValue('name', option.data.name);
                    if (props.onChangeRelation) props.onChangeRelation(option.data);
                  } else {
                    setData(initialValues);
                    setComboOption(null);
                    setValue('id', null);
                    setValue('name', '');
                    if (props.onChangeRelation) props.onChangeRelation(undefined);
                  }
                }}
                renderOption={(props, option) => (
                  <li {...props}>
                    <Typography noWrap>
                      [{option.value}] {option.label}
                    </Typography>
                  </li>
                )}
                renderInput={(params) => (
                  <TimedTextField
                    {...params}
                    label="Grupos de Usuário"
                    placeholder="Pesquisar um grupo de usuário"
                    type="text"
                    size="small"
                    onChange={(e) => setSearch(e.target.value)}
                    helperText={
                      <Typography color="red" variant="caption" component="span">
                        {errors.id?.message}
                      </Typography>
                    }
                  />
                )}
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={2}>
          <Controller
            name="id"
            control={control}
            defaultValue={data.id}
            render={({ field: { onChange } }) => (
              <TextField
                id="form-group-user-id"
                label="ID"
                type="text"
                disabled={true}
                value={data.id}
                autoComplete={'off'}
                fullWidth
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setData({ ...data, id: parseInt(e.target.value) });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={props.type === 'update' ? 10 : 12}>
          <Controller
            name="name"
            control={control}
            defaultValue={data.name}
            render={({ field: { onChange } }) => (
              <TextField
                id="form-group-user-nome"
                label="Nome*"
                type="text"
                value={data.name}
                autoComplete={'off'}
                fullWidth
                inputProps={{
                  maxLength: 191,
                }}
                variant="outlined"
                size="small"
                helperText={
                  <Typography color="red" variant="caption" component="span">
                    {errors.name?.message}
                  </Typography>
                }
                onChange={(e) => {
                  setData({ ...data, name: e.target.value });
                  onChange(e);
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12}>
          <Controller
            name="menu_id"
            control={control}
            defaultValue={data.menu_id}
            render={({ field: { onChange } }) => (
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="update-select">Menu</InputLabel>
                <Select
                  inputProps={{ style: { textAlign: 'center' } }}
                  size="small"
                  labelId="update-select"
                  id="simple-update-select"
                  value={data.menu_id}
                  onChange={(e) => {
                    setData({ ...data, menu_id: +e.target.value });
                    onChange(e);
                    if (props.setSelectedMenu)
                      props.setSelectedMenu(+e.target.value)
                  }}
                  label="Menu"
                >
                  <MenuItem aria-label="" value="">Limpar</MenuItem>
                  {optionsMenu.map(item => (
                    <MenuItem value={item.value}>{item.label}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          />
        </Grid>
      </Grid>

      {/*Buttons*/}
      <Grid container xs={12} justifyContent="flex-end" marginTop={3}>
        <Button onClick={props.onCancel} color="primary">
          Voltar
        </Button>
        <LoadingButton type="submit" color="primary" loading={loading} variant="contained">
          Salvar
        </LoadingButton>
      </Grid>
      <Toast
        open={toast.open}
        onClose={handleCloseToast}
        severity={toast.severity}
        message={toast.message}
      />
    </FormBox>
  );
};

export default FormGroupUser;
