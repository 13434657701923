import FormContainer from 'app/components/Containers/FormContainer';
import FormWorkflowPhases from 'app/components/Forms/WorkflowPhases';
import WorkflowPhasesContext from 'app/contexts/WorkflowPhasesContext';
import { IDataRelation } from 'app/types/data/IData';
import { IDataWorkflowPhases, WorkflowPhasesRelations } from 'app/types/data/IDataWorkflowPhases';
import DisplayActivity from 'app/views/partners/activity/components/DisplayActivity';
import React from 'react';
import { useLocation } from 'react-router-dom';
import BpmOrder_Api from 'app/api/BpmOrder_Api';
import useAuth from 'app/hooks/useAuth';
import { IDataBpmCampo } from 'app/types/data/IDataBpmCampo';
import Funcionalidade_Api from 'app/api/Funcionalidade_Api';

declare interface IWorkflowPhasesFormContainer {
  data?: IDataWorkflowPhases;
  relation?: IDataRelation<WorkflowPhasesRelations>;
  onClickAttach?: () => void;
  onSubmit: (item?: IDataWorkflowPhases) => void;
  cancel: () => void;
  setSelected: (data) => void;
  uuid?: string;
  campos?: IDataBpmCampo[]
  sequency?: any
  typeForm?: string | undefined
}

const WorkflowPhasesFormContainer = (props: IWorkflowPhasesFormContainer) => {
  const { menu } = useAuth();
  const location = useLocation()
  const [tabsMenu, setTabsMenu] = React.useState<any[]>([])
  const [data, setData] = React.useState<IDataWorkflowPhases | undefined>(props.data);
  const [activeTab, setActiveTab] = React.useState<string>('0')

  async function getItemsMenu() {
    try {
      const funcionalidade = menu?.itens?.find(x => x.funcionalidade && `/${x.funcionalidade.rota}` === location.pathname)
      let tabMenu = [
        {
          title: 'Atividades',
          component: (
            <DisplayActivity
              displayName="Atividades"
              relation={{ id: props.data!.id as number, relation: 'WorkflowPhases' }}
              view="grid"
            />
          ),
        },
      ]
      if (!funcionalidade) {
        setTabsMenu(tabMenu)
        return;
      }
      let res;
      if (props.relation) {
        const funcFilhos = await Funcionalidade_Api.listPai(funcionalidade.funcionalidade.id);
        if (funcFilhos.find(f => f.nome === 'WORKFLOW FASES')) {

          const filho = funcFilhos.find(f => f.nome === 'WORKFLOW FASES')!.corelacao_id

          if (!!filho) {
            const apx = await BpmOrder_Api.getBpmItemByFuncionalidadeFilho(filho, menu.id);

            // res = await BpmOrder_Api.getBpmItem(apx.menu_item_id ?? 0);
            res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("WORKFLOW FASES", menu.id)
          } else {
            res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
          }

        } else {
          res = await BpmOrder_Api.getBpmItemByFuncionalidadeFilhoCerto("WORKFLOW FASES", menu.id);

        }
      } else {
        res = await BpmOrder_Api.getBpmItem(funcionalidade.id)
      } if (res && res.length > 0) {
        let nItems = res

        if (nItems.length > 0)
          nItems.shift()
        nItems.map(x => {
          x.idFinal = res.find((menu: any) => menu.funcionalidade_id === x.id)?.id;
          x.order = res.find((menu: any) => menu.funcionalidade_id === x.id)?.order;
          x.bpmCampos = res.find((menu: any) => menu.funcionalidade_id === x.id)?.campos ?? [];
          (x.campos ?? []).map(y => {
            const teste = x.bpmCampos?.find(f => f.funcionalidade_campo_id === y.id)
            y.hide = teste?.hidden;
            y.required = teste?.required;
          })
        })
        nItems = nItems.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

        tabMenu = [
          {
            title: 'Atividades',
            component: (
              <DisplayActivity
                displayName="Atividades"
                relation={{ id: props.data!.id as number, relation: 'WorkflowPhases' }}
                onSubmit={() => {
                  setActiveTab(nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atividades')?.sequency)
                  localStorage.setItem('sequencyName', nItems[nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atividades')?.sequency]?.nome)
                }}
                sequency={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atividades')?.sequency}
                campos={nItems.find(x => x.nome.split(' - ')[0].toLowerCase() === 'atividades')?.campos}
                view="grid"
              />
            ),
          },
        ]

        let nTabs: any[] = []
        if (nItems.length === 0) {
          nTabs = tabMenu
        } else {
          nItems.map(x => {
            const menuItem = tabMenu.find(m => m.title.toLowerCase() === x.nome.split(' - ')[0].toLowerCase())
            if (menuItem) {
              const menuItem = tabMenu.find(m => m.title.toLowerCase() === x.nome.split(' - ')[0].toLowerCase())
              if (menuItem) {
                const nomeTab = x.nome.split(' - ')
                nTabs.push({
                  ...menuItem, ...x,
                  title: nomeTab.length > 1 ? nomeTab[1] : menuItem.title
                })
              }
            }
          })
        }

        const uniqueData = nTabs.filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.nome === value.nome
          ))
        )
        setTabsMenu(uniqueData)
      } else {
        setTabsMenu(tabMenu)
      }
    } catch (error) {
      console.log(error)
    }
  }

  React.useEffect(() => {
    setData(props.data)

    getItemsMenu()
  }, [props.data]);

  return (
    <WorkflowPhasesContext.Provider value={data}>
      <FormContainer
        key={data?.id}
        activeTab={activeTab}
        uuid={props?.uuid}
        mainForm={
          <FormWorkflowPhases
            setSelected={(data) => props.setSelected(data)}
            data={data}
            onChangeRelation={(selectedData) => setData(selectedData)}
            relation={props.relation}
            type="update"
            onSubmit={(item) => props.onSubmit(item)}
            onCancel={props.cancel}
            campos={props.campos}
            sequency={props.sequency}
            typeForm={props.typeForm}
          />
        }
        tabs={
          !data?.id
            ? undefined
            : tabsMenu
        }
      />
    </WorkflowPhasesContext.Provider>
  );
};

export default WorkflowPhasesFormContainer;
